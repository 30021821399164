<!--
  功能：活动页
  作者：Vhomelu
  邮箱：vhomelu@xiaoe-tech.com
  时间：2022年02月21日 16:21:43
  版本：v1.0
-->
<template>
    <div class="activity">
        <img class="activity-img" :src="pageConfig.activity_img" />
        <div class="activity-introduction">
            <div class="title" v-if="pageConfig.card_desc_title">{{ pageConfig.card_desc_title }}</div>
            <div class="description" v-if="pageConfig.activity_desc">{{ pageConfig.activity_desc }}</div>
        </div>
        <div class="activity-footer">
            <button class="enter-button" @click="enterGroupPage">{{ pageConfig.card_btn_word }}</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'demo',
    props: {
        pageConfig: {
            type: Object,
            default: () => {}
        }
    },
    methods: {
        enterGroupPage() {
            this.$emit('change-page', 'groupCard');
        }
    }
};
</script>

<style lang="scss" scoped>
$constantSafeArea: constant(safe-area-inset-bottom);
$envSafeArea: env(safe-area-inset-bottom);

.activity {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;

    &-img {
        width: 100%;
    }

    &-introduction {
        padding: 0.24rem 0.32rem;
        font-size: 0.32rem;
        overflow-y: auto;
        background-color: #fff;

        .title {
            color: #000;
            display: flex;
            align-items: center;

            &::before {
                content: '';
                width: 0.04rem;
                height: 0.32rem;
                background: #1472ff;
                margin-right: 0.08rem;
                display: block;
            }
        }

        .description {
            line-height: 0.44rem;
            padding: 0.24rem 0 calc(1.12rem + #{$envSafeArea});
            padding: 0.24rem 0 calc(1.12rem + #{$constantSafeArea});
            white-space: pre-wrap;
            word-break: break-all;
            color: #323233;
        }
    }

    &-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        bottom: 0;
        right: 0;
        padding-bottom: $constantSafeArea;
        padding-bottom: $envSafeArea;
        width: 100%;
        height: 1.12rem;
        border-top: 0.02rem solid #ebebeb;
        background-color: #fff;
        font-size: 0.32rem;

        .enter-button {
            width: 6.86rem;
            height: 0.8rem;
            line-height: 0.8rem;
            color: #fff;
            background: #1472ff;
            border: #1472ff;
            border-radius: 0.4rem;
            text-align: center;
        }
    }
}
</style>
