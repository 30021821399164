<!--
  功能：群组活码页
  作者：Vhomelu
  邮箱：vhomelu@xiaoe-tech.com
  时间：2022年02月21日 16:09:18
  版本：v1.0
-->
<template>
    <div class="traffic-group">
        <activity-page
            v-if="currentPage === 'activity'"
            :pageConfig="activityPageConfig"
            @change-page="changeCurrentPage"
        />

        <group-card
            v-else-if="currentPage === 'groupCard'"
            :pageConfig="pageConfig"
            :needReportInside="true"
            :customerGroupId="customerGroupId"
        />
    </div>
</template>

<script>
import ActivityPage from './activity-page';
import AttractiveGroup from '../attractive-group';
import Fingerprint2 from 'fingerprintjs2';

export default {
    name: 'trafficGroup',
    props: {
        pageConfig: {
            type: Object,
            default: () => {}
        },
        activityPageConfig: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        ActivityPage,
        'group-card': AttractiveGroup
    },
    data() {
        return {
            currentPage: '',
            customerGroupId: null,
            isWechat: /MicroMessenger/i.test(window.navigator.userAgent) && !/wxwork/i.test(window.navigator.userAgent),
            isPc: !/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent),
        };
    },
    watch: {
        'pageConfig.is_show_activity_page': {
            handler(currentValue) {
                this.currentPage = currentValue === 1 && this.pageConfig.delete_state === 1 ? 'activity' : 'groupCard';
                this.$emit('change-page', this.currentPage);
            },
            immediate: true
        }
    },
    methods: {
        changeCurrentPage(page) {
            // 手机微信内直接跳转添加群聊页
            if (this.pageConfig.group_code_is_permanent && !this.isPc && this.isWechat) {
                location.href = this.pageConfig.group_code_url;
                return;
            }
            this.currentPage = page;
            this.$emit('change-page', this.currentPage);
        },

        //上报访问二维码次数
        addPageUv(app_id, group_card_id) {
            Fingerprint2.get((components) => {
                const values = components.map((component, index) => {
                    if (index === 0) {
                        //把微信浏览器里UA的wifi或4G等网络替换成空,不然切换网络会ID不一样
                        return component.value.replace(/\bNetType\/\w+\b/, '');
                    }
                    return component.value;
                });
                // 生成最终浏览器指纹
                this.device_id = Fingerprint2.x64hash128(values.join(''), 31);
                this.$ajax('add_group_card_uv', { app_id, group_card_id, device_id: this.device_id }).catch(() =>
                    console.error('上报异常')
                );
            });
        },

        getCustomerGroupId() {
            const { lead_link_id: group_card_id, app_id } = this.$route.query;
            this.$ajax('get_wx_group_id', { app_id, group_card_id })
                .then(({ data: { code, data } }) => {
                    if (code === 0) {
                        this.customerGroupId = data.group_wx_codes_id;
                    } else {
                        console.error(`获取群组Id失败，${data.msg}`);
                    }
                })
                .catch((err) => console.error(err));
        }
    },

    created() {
        this.getCustomerGroupId();
    },

    mounted() {
        const { lead_link_id, app_id } = this.$route.query;
        this.addPageUv(app_id, lead_link_id);
    }
};
</script>
